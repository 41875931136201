import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {NextSeo} from 'next-seo';
import Link from 'next/link';
import {FunctionComponent, useEffect, useState} from 'react';
import {Columns, Grid, MoreHorizontal, PlusCircle, Search} from 'react-feather';
import toast from 'react-hot-toast';
import Api from '../api';
import Input from '../components/input';
import StoreIcon from '../components/store-icon';
import {openModal, selectStores, setStores} from '../entities/app';
import {useAppDispatch, useAppSelector} from '../hooks';
import {Store} from '../interfaces';

dayjs.extend(relativeTime);

const api = Api.instance;

interface StoreCardProps {
	store: Store;
}

const StoreCard: FunctionComponent<StoreCardProps> = ({store}) => (
	<Link href={`/stores/${store.id}`}>
		<div className="relative cursor-pointer overflow-hidden rounded-[10px] border border-[#2e3140] bg-gray hover:border-white">
			<MoreHorizontal
				size={18}
				color="#8a8d9e"
				className="absolute top-4 right-4"
			/>

			<div className="p-4">
				<StoreIcon id={store.id} image={store.image} className="mb-2.5" />

				<h2 className="text-lg font-bold">{store.name}</h2>
				<p
					onClick={event => {
						event.stopPropagation();
						window.location.href = `https://${store.slug}.waves.store`;
					}}
					className="mb-[15px] text-xs text-[#ffadde] hover:underline"
				>
					{store.slug}.waves.store
				</p>
				<p className="text-xs text-[#8a8d9e]">{store.description}</p>
			</div>

			{/* todo: Remove h-full */}
			<div className="h-full bg-gray-dark p-4">
				<p className="text-xs text-[#8a8d9e]">
					{dayjs(Number(store.id) / 4194304 + 1562544000000).fromNow()}
				</p>
			</div>
		</div>
	</Link>
);

const NewStoreCard = () => {
	const dispatch = useAppDispatch();

	return (
		<button
			type="button"
			className="relative block w-full rounded-lg border-2 border-dashed border-[#2e3140] p-12 text-center"
			onClick={() => dispatch(openModal({name: 'newStore', props: {}}))}
		>
			<svg
				className="mx-auto h-12 w-12 text-[#8a8d9e]"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				aria-hidden="true"
			>
				<path
					vectorEffect="non-scaling-stroke"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
				/>
			</svg>
			<h3 className="mt-2 text-sm font-medium">New Store</h3>
			<p className="mt-1 text-sm text-[#8a8d9e]">
				Get started by creating a new store.
			</p>
		</button>
	);
};

const MyStores = () => {
	const dispatch = useAppDispatch();
	const [search, setSearch] = useState('');
	const stores = useAppSelector(selectStores);

	const getStores = async () => {
		const {data, error} = await api.makeRequest('/stores');
		if (error) {
			toast.error(error.message);
		} else {
			dispatch(setStores(data.stores));
		}
	};

	useEffect(() => {
		getStores();
	}, []);

	return (
		<div>
			<NextSeo title="My Stores" />

			<div className="mb-5 flex justify-between">
				<div className="flex">
					<img src="/img/star.svg" alt="Star" className="mr-3" />
					<h1 className="text-2xl font-bold">My Stores</h1>
				</div>

				<div className="flex items-center">
					<Columns size={20} color="#525564" className="mr-5 cursor-pointer" />
					<Grid size={20} color="#525564" className="cursor-pointer" />
				</div>
			</div>

			<div className="mb-5 flex justify-between">
				<div
					className="flex cursor-pointer items-center"
					onClick={() => dispatch(openModal({name: 'newStore', props: {}}))}
				>
					<PlusCircle size={18} color={'#8A8D9E'} className="mr-2" />
					<p className="text-xs text-[#8a8d9e]">New Store</p>
				</div>

				<Input
					placeholder="Search"
					icon={<Search />}
					value={search}
					onChange={event => setSearch(event.target.value)}
				/>
			</div>

			{Object.keys(stores).length ? (
				<div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr));] gap-4">
					{Object.values(stores)
						.filter(({name}) =>
							name.toLowerCase().includes(search.toLowerCase()),
						)
						.map(store => (
							<StoreCard store={store} />
						))}
					<NewStoreCard />
				</div>
			) : (
				<NewStoreCard />
			)}
		</div>
	);
};

export default MyStores;
